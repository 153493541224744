import React, { Component, useState } from 'react';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "../../../style/css/partnyors.css"
import normal from "../../../assets/icon/normal.svg"
import Navbar from './Navbar2';
import Swal from "sweetalert2";
import Axios from '../../../utils/axios';
import { useHistory } from 'react-router';
import "../../../i18n";
import { useTranslation } from 'react-i18next';
import img1 from '../../../assets/university/closeup.png'
import img2 from '../../../assets/university/Handshake of pale skin and brown skin hands.png'
function Partnyors() {

    const { t, i18n } = useTranslation();

    const history = useHistory()
    const [partnerData, setPartnerData] = useState({
        phone_number: '',
        email: '',
        partnership_type: '',
        request_content: ''
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setPartnerData(state => ({ ...state, [name]: value }))
    }
    const onSubmit = async () => {
        try {
            const res = await Axios.post('/partner/partnership-request/', partnerData)
            const status = res.status
            if (status === 201) {
                Swal.fire({
                    icon: 'success',
                    text: `${t("okey")}`
                }).then(() => history.push('/'))
            }
            ;
        } catch (error) {
        }
    }
    return (
        <React.Fragment>
            <div className="navPart">
                <Navbar />
            </div>

            <div>
                <div className="container-fluid max-width px-6  become-partner">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="section_title text-pr">
                                {t("partt1")}
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-none d-lg-flex col-10 col-lg-4 tasks">
                            <div className="row">
                                <div className="col-12 text">
                                    <p>{t("partt2")}</p>
                                </div>
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M38.2697 18.4886C38.1673 18.4821 38.0558 18.4821 37.9402 18.4822L37.9113 18.4822H33.3779C29.6412 18.4822 26.4438 21.4466 26.4438 25.3125C26.4438 29.1784 29.6412 32.1429 33.3779 32.1429H37.9113L37.9402 32.1429C38.0558 32.1429 38.1673 32.143 38.2697 32.1365C39.7875 32.0399 41.1298 30.8427 41.2427 29.1707C41.2501 29.061 41.25 28.9429 41.25 28.8333L41.25 28.8036V21.8214L41.25 21.7917C41.25 21.6822 41.2501 21.564 41.2427 21.4543C41.1298 19.7823 39.7875 18.5852 38.2697 18.4886ZM32.9759 27.1339C33.938 27.1339 34.7178 26.3184 34.7178 25.3125C34.7178 24.3066 33.938 23.4911 32.9759 23.4911C32.0139 23.4911 31.234 24.3066 31.234 25.3125C31.234 26.3184 32.0139 27.1339 32.9759 27.1339Z" fill="#015EDF" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M37.939 34.875C38.2085 34.868 38.4124 35.1195 38.3393 35.381C37.9765 36.6787 37.4007 37.7847 36.4767 38.716C35.1242 40.079 33.4092 40.684 31.2904 40.9711C29.2316 41.2501 26.6009 41.25 23.2797 41.25H19.4613C16.1401 41.25 13.5094 41.2501 11.4506 40.9711C9.33173 40.684 7.61676 40.079 6.2643 38.716C4.91183 37.3529 4.31162 35.6244 4.02675 33.4889C3.74995 31.4139 3.74997 28.7626 3.75 25.4153V25.2097C3.74997 21.8624 3.74995 19.2111 4.02675 17.1361C4.31162 15.0006 4.91183 13.2721 6.26429 11.9091C7.61676 10.546 9.33173 9.94103 11.4506 9.65393C13.5094 9.37495 16.14 9.37497 19.4613 9.375L23.2797 9.375C26.6009 9.37497 29.2316 9.37495 31.2904 9.65392C33.4092 9.94103 35.1242 10.546 36.4767 11.909C37.4007 12.8403 37.9765 13.9463 38.3393 15.244C38.4124 15.5055 38.2084 15.757 37.939 15.75L33.3777 15.75C28.2513 15.75 23.7328 19.8317 23.7328 25.3125C23.7328 30.7934 28.2513 34.875 33.3777 34.875L37.939 34.875ZM10.5271 16.6607C9.77853 16.6607 9.17169 17.2723 9.17169 18.0268C9.17169 18.7812 9.77853 19.3929 10.5271 19.3929H17.756C18.5046 19.3929 19.1114 18.7812 19.1114 18.0268C19.1114 17.2723 18.5046 16.6607 17.756 16.6607H10.5271Z" fill="#015EDF" />
                                            <path d="M14.5813 7.54572L18.254 4.83986C20.2264 3.38671 22.8986 3.38671 24.871 4.83986L28.563 7.55995C27.0193 7.49992 25.2954 7.49996 23.4054 7.5H19.3356C17.6085 7.49996 16.0202 7.49992 14.5813 7.54572Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt3")}</p>
                                    </div>
                                </div>
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path d="M11.6664 8.62727C12.5 8.0703 13.3752 7.58723 14.2821 7.1811C16.8543 6.02915 18.1405 5.45317 19.8515 6.56169C21.5625 7.6702 21.5625 9.48857 21.5625 13.1253V15.9378C21.5625 19.4733 21.5625 21.2411 22.6609 22.3395C23.7592 23.4378 25.527 23.4378 29.0625 23.4378H31.875C35.5118 23.4378 37.3301 23.4378 38.4386 25.1488C39.5471 26.8599 38.9712 28.146 37.8192 30.7183C37.4131 31.6251 36.93 32.5004 36.3731 33.3339C34.4158 36.2632 31.6339 38.5462 28.3791 39.8944C25.1243 41.2426 21.5427 41.5954 18.0875 40.9081C14.6322 40.2208 11.4583 38.5243 8.96717 36.0332C6.47605 33.542 4.77957 30.3681 4.09227 26.9129C3.40497 23.4576 3.75772 19.8761 5.1059 16.6213C6.45409 13.3665 8.73716 10.5845 11.6664 8.62727Z" fill="#015EDF" />
                                            <path d="M40.2113 13.2548C38.6892 9.39095 35.6094 6.31122 31.7456 4.78908C28.8552 3.65041 26.25 6.26879 26.25 9.3754V16.8754C26.25 17.9109 27.0895 18.7504 28.125 18.7504H35.625C38.7316 18.7504 41.35 16.1452 40.2113 13.2548Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt4")}</p>
                                    </div>
                                </div>
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path d="M11.7374 40.1032H11.25C9.48223 40.1032 8.59835 40.1032 8.04917 39.554C7.5 39.0049 7.5 38.121 7.5 36.3532V34.2683C7.5 33.2961 7.5 32.81 7.74971 32.3759C7.99942 31.9418 8.37614 31.7227 9.12957 31.2845C14.0898 28.3996 21.1342 26.7756 25.8357 29.5798C26.1516 29.7682 26.4357 29.9956 26.6785 30.2684C27.7249 31.4437 27.6487 33.2177 26.4427 34.2703C26.188 34.4926 25.9165 34.6612 25.6432 34.7197C25.8678 34.6937 26.0831 34.6639 26.2886 34.6311C27.9974 34.3585 29.4319 33.4453 30.7452 32.4533L34.1337 29.8936C35.3282 28.9912 37.1012 28.9911 38.296 29.8932C39.3716 30.7054 39.7005 32.0426 39.0205 33.1326C38.2275 34.4038 37.1102 36.03 36.0374 37.0236C34.963 38.0186 33.3635 38.907 32.0578 39.5372C30.6113 40.2354 29.0134 40.6377 27.388 40.9008C24.0916 41.4344 20.6561 41.3529 17.3931 40.6807C15.5485 40.3007 13.6327 40.1032 11.7374 40.1032Z" fill="#015EDF" />
                                            <path d="M20.3649 6.30628C21.3149 4.60209 21.7899 3.75 22.5 3.75C23.2101 3.75 23.6851 4.60209 24.6351 6.30628L24.8809 6.74717C25.1509 7.23145 25.2858 7.47359 25.4963 7.63336C25.7068 7.79312 25.9689 7.85243 26.4931 7.97104L26.9703 8.07902C28.8151 8.49642 29.7375 8.70511 29.9569 9.4108C30.1764 10.1165 29.5476 10.8518 28.2899 12.3224L27.9646 12.7029C27.6072 13.1208 27.4285 13.3298 27.3481 13.5883C27.2677 13.8468 27.2947 14.1256 27.3488 14.6832L27.398 15.1908C27.5881 17.1529 27.6832 18.134 27.1086 18.5702C26.5341 19.0063 25.6705 18.6087 23.9432 17.8134L23.4964 17.6076C23.0056 17.3816 22.7601 17.2686 22.5 17.2686C22.2399 17.2686 21.9944 17.3816 21.5036 17.6076L21.0568 17.8134C19.3295 18.6087 18.4659 19.0063 17.8914 18.5702C17.3168 18.134 17.4119 17.153 17.602 15.1908L17.6512 14.6832C17.7053 14.1256 17.7323 13.8468 17.6519 13.5883C17.5715 13.3298 17.3928 13.1208 17.0354 12.7029L16.7101 12.3224C15.4524 10.8518 14.8236 10.1165 15.0431 9.4108C15.2625 8.70511 16.1849 8.49642 18.0297 8.07902L18.5069 7.97104C19.0311 7.85243 19.2933 7.79312 19.5037 7.63336C19.7142 7.47359 19.8491 7.23145 20.1191 6.74718L20.3649 6.30628Z" fill="#015EDF" />
                                            <path d="M36.4324 14.4031C36.9074 13.551 37.1449 13.125 37.5 13.125C37.8551 13.125 38.0926 13.551 38.5676 14.4031L38.6904 14.6236C38.8254 14.8657 38.8929 14.9868 38.9981 15.0667C39.1034 15.1466 39.2344 15.1762 39.4965 15.2355L39.7352 15.2895C40.6576 15.4982 41.1187 15.6026 41.2285 15.9554C41.3382 16.3082 41.0238 16.6759 40.395 17.4112L40.2323 17.6015C40.0536 17.8104 39.9642 17.9149 39.9241 18.0441C39.8839 18.1734 39.8974 18.3128 39.9244 18.5916L39.949 18.8454C40.044 19.8265 40.0916 20.317 39.8043 20.5351C39.5171 20.7532 39.0852 20.5543 38.2216 20.1567L37.9982 20.0538C37.7528 19.9408 37.6301 19.8843 37.5 19.8843C37.3699 19.8843 37.2472 19.9408 37.0018 20.0538L36.7784 20.1567C35.9148 20.5543 35.4829 20.7532 35.1957 20.5351C34.9084 20.317 34.956 19.8265 35.051 18.8454L35.0756 18.5916C35.1026 18.3128 35.1161 18.1734 35.0759 18.0441C35.0358 17.9149 34.9464 17.8104 34.7677 17.6015L34.605 17.4112C33.9762 16.6759 33.6618 16.3082 33.7715 15.9554C33.8813 15.6026 34.3424 15.4982 35.2648 15.2895L35.5035 15.2355C35.7656 15.1762 35.8966 15.1466 36.0019 15.0667C36.1071 14.9868 36.1746 14.8657 36.3096 14.6236L36.4324 14.4031Z" fill="#015EDF" />
                                            <path d="M6.43244 14.4031C6.90743 13.551 7.14493 13.125 7.5 13.125C7.85507 13.125 8.09257 13.551 8.56756 14.4031L8.69045 14.6236C8.82543 14.8657 8.89292 14.9868 8.99815 15.0667C9.10338 15.1466 9.23443 15.1762 9.49654 15.2355L9.73517 15.2895C10.6576 15.4982 11.1187 15.6026 11.2285 15.9554C11.3382 16.3082 11.0238 16.6759 10.395 17.4112L10.2323 17.6015C10.0536 17.8104 9.96425 17.9149 9.92405 18.0441C9.88386 18.1734 9.89737 18.3128 9.92438 18.5916L9.94898 18.8454C10.044 19.8265 10.0916 20.317 9.80432 20.5351C9.51706 20.7532 9.08525 20.5543 8.22162 20.1567L7.99819 20.0538C7.75278 19.9408 7.63007 19.8843 7.5 19.8843C7.36993 19.8843 7.24722 19.9408 7.00181 20.0538L6.77838 20.1567C5.91475 20.5543 5.48294 20.7532 5.19568 20.5351C4.90842 20.317 4.95595 19.8265 5.05102 18.8454L5.07562 18.5916C5.10263 18.3128 5.11614 18.1734 5.07595 18.0441C5.03575 17.9149 4.94641 17.8104 4.76772 17.6015L4.60504 17.4112C3.97622 16.6759 3.66181 16.3082 3.77153 15.9554C3.88126 15.6026 4.34245 15.4982 5.26483 15.2895L5.50346 15.2355C5.76557 15.1762 5.89662 15.1466 6.00185 15.0667C6.10708 14.9868 6.17457 14.8657 6.30955 14.6236L6.43244 14.4031Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt5")}</p>
                                    </div>
                                </div>
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M3.80225 21.0938C4.52136 11.3952 12.618 3.75 22.5003 3.75C20.9424 3.75 19.5554 4.43289 18.4143 5.47711C17.279 6.5161 16.3195 7.96645 15.5435 9.68091C14.7629 11.4055 14.1566 13.4225 13.7462 15.6115C13.4172 17.3661 13.217 19.216 13.1503 21.0938H3.80225ZM3.80225 23.9062H13.1503C13.217 25.784 13.4172 27.6339 13.7462 29.3885C14.1566 31.5775 14.7629 33.5945 15.5435 35.3191C16.3195 37.0335 17.279 38.4839 18.4143 39.5229C19.5554 40.5671 20.9424 41.25 22.5003 41.25C12.618 41.25 4.52136 33.6048 3.80225 23.9062Z" fill="#015EDF" />
                                            <path d="M22.5003 6.36628C21.9847 6.36628 21.3185 6.58568 20.5439 7.29453C19.7636 8.00861 18.9824 9.12777 18.2923 10.6526C17.6067 12.1673 17.05 13.9958 16.6669 16.0391C16.3652 17.6483 16.178 19.3541 16.1123 21.0938H28.8883C28.8226 19.3541 28.6354 17.6483 28.3337 16.0391C27.9506 13.9958 27.3939 12.1673 26.7083 10.6526C26.0182 9.12778 25.237 8.00862 24.4567 7.29453C23.6821 6.58568 23.0159 6.36628 22.5003 6.36628Z" fill="#015EDF" />
                                            <path d="M16.6669 28.9609C17.05 31.0042 17.6067 32.8327 18.2923 34.3474C18.9824 35.8722 19.7636 36.9914 20.5439 37.7055C21.3185 38.4143 21.9847 38.6337 22.5003 38.6337C23.0159 38.6337 23.6821 38.4143 24.4567 37.7055C25.237 36.9914 26.0182 35.8722 26.7083 34.3474C27.3939 32.8327 27.9506 31.0042 28.3337 28.9609C28.6354 27.3517 28.8226 25.6459 28.8883 23.9062H16.1123C16.178 25.6459 16.3652 27.3517 16.6669 28.9609Z" fill="#015EDF" />
                                            <path d="M22.5003 3.75C24.0582 3.75 25.4452 4.43289 26.5863 5.47711C27.7217 6.5161 28.6811 7.96646 29.4571 9.68091C30.2377 11.4055 30.8441 13.4225 31.2544 15.6115C31.5834 17.3661 31.7836 19.216 31.8504 21.0938H41.1984C40.4793 11.3952 32.3826 3.75 22.5003 3.75Z" fill="#015EDF" />
                                            <path d="M31.2544 29.3885C30.8441 31.5775 30.2377 33.5945 29.4571 35.3191C28.6811 37.0335 27.7217 38.4839 26.5863 39.5229C25.4452 40.5671 24.0582 41.25 22.5003 41.25C32.3826 41.25 40.4793 33.6048 41.1984 23.9062H31.8504C31.7836 25.784 31.5834 27.6339 31.2544 29.3885Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt6")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex col-4 tasks tasks2">
                            <div className="row">
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <circle cx="18.75" cy="12.6562" r="7.5" fill="#015EDF" />
                                            <ellipse cx="18.75" cy="33.2812" rx="13.125" ry="7.5" fill="#015EDF" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M34.4195 4.43295C34.819 3.76698 35.6828 3.55103 36.3488 3.95061L35.6253 5.15646C36.3488 3.95061 36.3482 3.95023 36.3488 3.95061L36.3514 3.95218L36.3544 3.95396L36.3613 3.95819L36.3793 3.96928C36.3931 3.97786 36.4104 3.98881 36.431 4.00216C36.4723 4.02884 36.5269 4.06517 36.5929 4.11135C36.7247 4.20364 36.9028 4.33583 37.1115 4.50975C37.528 4.85678 38.0726 5.37522 38.6149 6.08031C39.7078 7.50106 40.7816 9.67024 40.7816 12.6565C40.7816 15.6427 39.7078 17.8119 38.6149 19.2326C38.0726 19.9377 37.528 20.4561 37.1115 20.8032C36.9028 20.9771 36.7247 21.1093 36.5929 21.2016C36.5269 21.2478 36.4723 21.2841 36.431 21.3108C36.4104 21.3241 36.3931 21.3351 36.3793 21.3436L36.3613 21.3547L36.3544 21.359L36.3514 21.3607C36.3508 21.3611 36.3488 21.3623 35.6253 20.1565L36.3488 21.3623C35.6828 21.7619 34.819 21.5459 34.4195 20.88C34.0216 20.2168 34.234 19.3575 34.8934 18.9557L34.9038 18.9491C34.9175 18.9402 34.9435 18.9231 34.98 18.8975C35.0532 18.8462 35.1681 18.7616 35.311 18.6426C35.5977 18.4037 35.9906 18.0315 36.3857 17.5178C37.1678 16.5011 37.9691 14.9202 37.9691 12.6565C37.9691 10.3927 37.1678 8.81186 36.3857 7.79512C35.9906 7.28146 35.5977 6.90927 35.311 6.67037C35.1681 6.55131 35.0532 6.46671 34.98 6.41544C34.9435 6.38985 34.9175 6.37271 34.9038 6.36387L34.8934 6.35725C34.234 5.95547 34.0216 5.09613 34.4195 4.43295Z" fill="#015EDF" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M30.6175 8.27757C30.9648 7.58291 31.8095 7.30135 32.5042 7.64867L31.8753 8.90646C32.5042 7.64867 32.5035 7.6483 32.5042 7.64867L32.5072 7.6502L32.5105 7.65182L32.5175 7.65538L32.5336 7.66373L32.5741 7.6855C32.6046 7.70229 32.6421 7.72376 32.6857 7.75019C32.7726 7.80299 32.8843 7.87608 33.012 7.97189C33.2669 8.16305 33.5911 8.44856 33.9109 8.8483C34.5648 9.66561 35.1566 10.9086 35.1566 12.6565C35.1566 14.4043 34.5648 15.6473 33.9109 16.4646C33.5911 16.8644 33.2669 17.1499 33.012 17.341C32.8843 17.4368 32.7726 17.5099 32.6857 17.5627C32.6421 17.5892 32.6046 17.6106 32.5741 17.6274L32.5336 17.6492L32.5175 17.6575L32.5105 17.6611L32.5072 17.6627C32.5065 17.6631 32.5042 17.6642 31.8871 16.43L32.5042 17.6643C31.8095 18.0116 30.9648 17.73 30.6175 17.0354C30.2745 16.3493 30.5448 15.517 31.2207 15.1619C31.222 15.1611 31.2238 15.16 31.2261 15.1586C31.2416 15.1492 31.2765 15.1271 31.3245 15.091C31.4212 15.0185 31.5657 14.8939 31.7147 14.7077C31.9984 14.3531 32.3441 13.7211 32.3441 12.6565C32.3441 11.5918 31.9984 10.9598 31.7147 10.6053C31.5657 10.419 31.4212 10.2944 31.3245 10.2219C31.2765 10.1859 31.2416 10.1637 31.2261 10.1543C31.2238 10.1529 31.222 10.1519 31.2207 10.151C30.5448 9.79591 30.2745 8.96358 30.6175 8.27757Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt62")}</p>
                                    </div>
                                </div>
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.49587 6.49587C3.75 9.24175 3.75 13.6612 3.75 22.5C3.75 31.3388 3.75 35.7583 6.49587 38.5041C9.24175 41.25 13.6612 41.25 22.5 41.25C31.3388 41.25 35.7583 41.25 38.5041 38.5041C41.25 35.7583 41.25 31.3388 41.25 22.5C41.25 13.6612 41.25 9.24175 38.5041 6.49587C35.7583 3.75 31.3388 3.75 22.5 3.75C13.6612 3.75 9.24175 3.75 6.49587 6.49587ZM25.7812 18.75C25.7812 19.5266 26.4109 20.1562 27.1875 20.1562H28.48L24.7065 23.9298C24.5234 24.1129 24.2266 24.1129 24.0435 23.9298L21.0702 20.9565C19.7888 19.675 17.7112 19.675 16.4298 20.9565L12.1306 25.2556C11.5815 25.8048 11.5815 26.6952 12.1306 27.2444C12.6798 27.7935 13.5702 27.7935 14.1194 27.2444L18.4185 22.9452C18.6016 22.7621 18.8984 22.7621 19.0815 22.9452L22.0548 25.9185C23.3362 27.1999 25.4138 27.2 26.6952 25.9185L30.4688 22.145V23.4375C30.4688 24.2141 31.0984 24.8438 31.875 24.8438C32.6516 24.8438 33.2812 24.2141 33.2812 23.4375V18.75C33.2812 17.9733 32.6516 17.3438 31.875 17.3438H27.1875C26.4109 17.3438 25.7812 17.9733 25.7812 18.75Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt7")}</p>
                                    </div>
                                </div>
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.49587 6.49587C3.75 9.24175 3.75 13.6612 3.75 22.5C3.75 31.3388 3.75 35.7583 6.49587 38.5041C9.24175 41.25 13.6612 41.25 22.5 41.25C31.3388 41.25 35.7583 41.25 38.5041 38.5041C41.25 35.7583 41.25 31.3388 41.25 22.5C41.25 13.6612 41.25 9.24175 38.5041 6.49587C35.7583 3.75 31.3388 3.75 22.5 3.75C13.6612 3.75 9.24175 3.75 6.49587 6.49587ZM19.7683 14.0948C20.3039 13.5324 20.2822 12.6423 19.7198 12.1067C19.1574 11.5711 18.2673 11.5928 17.7317 12.1552L13.3929 16.7109L12.2683 15.5302C11.7327 14.9678 10.8426 14.9461 10.2802 15.4817C9.71777 16.0173 9.69606 16.9074 10.2317 17.4698L12.3745 19.7198C12.64 19.9985 13.008 20.1563 13.3929 20.1563C13.7777 20.1563 14.1458 19.9985 14.4112 19.7198L19.7683 14.0948ZM24.375 15.4688C23.5984 15.4688 22.9688 16.0984 22.9688 16.875C22.9688 17.6517 23.5984 18.2813 24.375 18.2813H33.75C34.5267 18.2813 35.1562 17.6517 35.1562 16.875C35.1562 16.0984 34.5267 15.4688 33.75 15.4688H24.375ZM19.7683 27.2198C20.3039 26.6574 20.2822 25.7673 19.7198 25.2317C19.1574 24.6961 18.2673 24.7178 17.7317 25.2802L13.3929 29.8359L12.2683 28.6552C11.7327 28.0928 10.8426 28.0711 10.2802 28.6067C9.71777 29.1423 9.69606 30.0324 10.2317 30.5948L12.3745 32.8448C12.64 33.1235 13.008 33.2813 13.3929 33.2813C13.7777 33.2813 14.1458 33.1235 14.4112 32.8448L19.7683 27.2198ZM24.375 28.5938C23.5984 28.5938 22.9688 29.2234 22.9688 30C22.9688 30.7767 23.5984 31.4063 24.375 31.4063H33.75C34.5267 31.4063 35.1562 30.7767 35.1562 30C35.1562 29.2234 34.5267 28.5938 33.75 28.5938H24.375Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt8")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex col-4 tasks tasks3">
                            <div className="row last-task">
                                <div className="col-12 img">
                                    <img src={img2} alt={404} />
                                </div>
                                <div className="col-12 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M33.4646 6.06494C33.75 6.75408 33.75 7.62772 33.75 9.375V35.625C33.75 37.3723 33.75 38.2459 33.4645 38.9351C33.4016 39.0871 33.329 39.234 33.2476 39.375C32.8372 40.086 32.2019 40.6469 31.4351 40.9646C30.7459 41.25 29.8723 41.25 28.125 41.25C26.3777 41.25 25.5041 41.25 24.8149 40.9646C24.0481 40.6469 23.4128 40.086 23.0024 39.375C22.921 39.234 22.8484 39.0871 22.7855 38.9351C22.5 38.2459 22.5 37.3723 22.5 35.625V9.375C22.5 7.62772 22.5 6.75408 22.7855 6.06494C22.8484 5.91288 22.921 5.76599 23.0024 5.625C23.4128 4.91403 24.0481 4.35307 24.8149 4.03545C25.5041 3.75 26.3777 3.75 28.125 3.75C29.8723 3.75 30.7459 3.75 31.4351 4.03545C32.2019 4.35307 32.8372 4.91403 33.2476 5.625C33.329 5.76599 33.4016 5.91288 33.4646 6.06494ZM29.5312 20.625C29.5312 19.8484 28.9016 19.2188 28.125 19.2188C27.3483 19.2188 26.7188 19.8484 26.7188 20.625V24.375C26.7188 25.1517 27.3483 25.7812 28.125 25.7812C28.9016 25.7812 29.5312 25.1516 29.5312 24.375V20.625Z" fill="#015EDF" />
                                            <path d="M19.9688 39.375C19.8241 38.8522 19.7621 38.3434 19.7297 37.8683C19.6874 37.2483 19.6875 36.5101 19.6875 35.7066V9.29342C19.6875 8.48989 19.6874 7.75169 19.7297 7.13172C19.7621 6.65664 19.8241 6.14781 19.9688 5.625H9.375C7.62772 5.625 6.75408 5.625 6.06494 5.91045C5.14608 6.29105 4.41605 7.02108 4.03545 7.93994C3.75 8.62908 3.75 9.50272 3.75 11.25C3.75 12.9973 3.75 13.8709 4.03545 14.5601C4.41605 15.4789 5.14608 16.2089 6.06494 16.5895C6.75408 16.875 7.62772 16.875 9.375 16.875C7.62772 16.875 6.75408 16.875 6.06494 17.1605C5.14608 17.5411 4.41605 18.2711 4.03545 19.1899C3.75 19.8791 3.75 20.7527 3.75 22.5C3.75 24.2473 3.75 25.1209 4.03545 25.8101C4.41605 26.7289 5.14608 27.4589 6.06494 27.8395C6.75408 28.125 7.62772 28.125 9.375 28.125C7.62772 28.125 6.75408 28.125 6.06494 28.4105C5.14608 28.7911 4.41605 29.5211 4.03545 30.4399C3.75 31.1291 3.75 32.0027 3.75 33.75C3.75 35.4973 3.75 36.3709 4.03545 37.0601C4.41605 37.9789 5.14608 38.7089 6.06494 39.0896C6.75408 39.375 7.62772 39.375 9.375 39.375H19.9688Z" fill="#015EDF" />
                                            <path d="M36.2814 39.3743C37.6029 39.3699 38.3382 39.3368 38.9351 39.0896C39.8539 38.7089 40.5839 37.9789 40.9646 37.0601C41.25 36.3709 41.25 35.4973 41.25 33.75C41.25 32.0027 41.25 31.1291 40.9646 30.4399C40.5839 29.5211 39.8539 28.7911 38.9351 28.4105C38.3819 28.1813 37.7099 28.1361 36.5625 28.1272V35.7066C36.5626 36.5101 36.5626 37.2483 36.5203 37.8683C36.4879 38.3432 36.426 38.8517 36.2814 39.3743Z" fill="#015EDF" />
                                            <path d="M36.5625 28.1228C37.7099 28.1139 38.3819 28.0687 38.9351 27.8395C39.8539 27.4589 40.5839 26.7289 40.9646 25.8101C41.25 25.1209 41.25 24.2473 41.25 22.5C41.25 20.7527 41.25 19.8791 40.9646 19.1899C40.5839 18.2711 39.8539 17.5411 38.9351 17.1605C38.3819 16.9313 37.7099 16.8861 36.5625 16.8772L36.5625 28.1228Z" fill="#015EDF" />
                                            <path d="M36.5625 16.8728C37.7099 16.8639 38.3819 16.8187 38.9351 16.5895C39.8539 16.2089 40.5839 15.4789 40.9646 14.5601C41.25 13.8709 41.25 12.9973 41.25 11.25C41.25 9.50272 41.25 8.62908 40.9646 7.93994C40.5839 7.02108 39.8539 6.29105 38.9351 5.91045C38.3382 5.66321 37.6029 5.63012 36.2814 5.62568C36.426 6.14826 36.4879 6.65685 36.5203 7.13172C36.5626 7.75169 36.5626 8.48987 36.5625 9.2934V16.8728Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt9")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* mobile */}
                        <div className="mobile-menu d-flex d-lg-none col-12 col-lg-4 tasks mb-5">
                            <div className="row align-items-start">
                                <div className="col-12 text">
                                    <p>{t("partt2")}</p>
                                </div>
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M38.2697 18.4886C38.1673 18.4821 38.0558 18.4821 37.9402 18.4822L37.9113 18.4822H33.3779C29.6412 18.4822 26.4438 21.4466 26.4438 25.3125C26.4438 29.1784 29.6412 32.1429 33.3779 32.1429H37.9113L37.9402 32.1429C38.0558 32.1429 38.1673 32.143 38.2697 32.1365C39.7875 32.0399 41.1298 30.8427 41.2427 29.1707C41.2501 29.061 41.25 28.9429 41.25 28.8333L41.25 28.8036V21.8214L41.25 21.7917C41.25 21.6822 41.2501 21.564 41.2427 21.4543C41.1298 19.7823 39.7875 18.5852 38.2697 18.4886ZM32.9759 27.1339C33.938 27.1339 34.7178 26.3184 34.7178 25.3125C34.7178 24.3066 33.938 23.4911 32.9759 23.4911C32.0139 23.4911 31.234 24.3066 31.234 25.3125C31.234 26.3184 32.0139 27.1339 32.9759 27.1339Z" fill="#015EDF" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M37.939 34.875C38.2085 34.868 38.4124 35.1195 38.3393 35.381C37.9765 36.6787 37.4007 37.7847 36.4767 38.716C35.1242 40.079 33.4092 40.684 31.2904 40.9711C29.2316 41.2501 26.6009 41.25 23.2797 41.25H19.4613C16.1401 41.25 13.5094 41.2501 11.4506 40.9711C9.33173 40.684 7.61676 40.079 6.2643 38.716C4.91183 37.3529 4.31162 35.6244 4.02675 33.4889C3.74995 31.4139 3.74997 28.7626 3.75 25.4153V25.2097C3.74997 21.8624 3.74995 19.2111 4.02675 17.1361C4.31162 15.0006 4.91183 13.2721 6.26429 11.9091C7.61676 10.546 9.33173 9.94103 11.4506 9.65393C13.5094 9.37495 16.14 9.37497 19.4613 9.375L23.2797 9.375C26.6009 9.37497 29.2316 9.37495 31.2904 9.65392C33.4092 9.94103 35.1242 10.546 36.4767 11.909C37.4007 12.8403 37.9765 13.9463 38.3393 15.244C38.4124 15.5055 38.2084 15.757 37.939 15.75L33.3777 15.75C28.2513 15.75 23.7328 19.8317 23.7328 25.3125C23.7328 30.7934 28.2513 34.875 33.3777 34.875L37.939 34.875ZM10.5271 16.6607C9.77853 16.6607 9.17169 17.2723 9.17169 18.0268C9.17169 18.7812 9.77853 19.3929 10.5271 19.3929H17.756C18.5046 19.3929 19.1114 18.7812 19.1114 18.0268C19.1114 17.2723 18.5046 16.6607 17.756 16.6607H10.5271Z" fill="#015EDF" />
                                            <path d="M14.5813 7.54572L18.254 4.83986C20.2264 3.38671 22.8986 3.38671 24.871 4.83986L28.563 7.55995C27.0193 7.49992 25.2954 7.49996 23.4054 7.5H19.3356C17.6085 7.49996 16.0202 7.49992 14.5813 7.54572Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt4")}</p>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path d="M11.6664 8.62727C12.5 8.0703 13.3752 7.58723 14.2821 7.1811C16.8543 6.02915 18.1405 5.45317 19.8515 6.56169C21.5625 7.6702 21.5625 9.48857 21.5625 13.1253V15.9378C21.5625 19.4733 21.5625 21.2411 22.6609 22.3395C23.7592 23.4378 25.527 23.4378 29.0625 23.4378H31.875C35.5118 23.4378 37.3301 23.4378 38.4386 25.1488C39.5471 26.8599 38.9712 28.146 37.8192 30.7183C37.4131 31.6251 36.93 32.5004 36.3731 33.3339C34.4158 36.2632 31.6339 38.5462 28.3791 39.8944C25.1243 41.2426 21.5427 41.5954 18.0875 40.9081C14.6322 40.2208 11.4583 38.5243 8.96717 36.0332C6.47605 33.542 4.77957 30.3681 4.09227 26.9129C3.40497 23.4576 3.75772 19.8761 5.1059 16.6213C6.45409 13.3665 8.73716 10.5845 11.6664 8.62727Z" fill="#015EDF" />
                                            <path d="M40.2113 13.2548C38.6892 9.39095 35.6094 6.31122 31.7456 4.78908C28.8552 3.65041 26.25 6.26879 26.25 9.3754V16.8754C26.25 17.9109 27.0895 18.7504 28.125 18.7504H35.625C38.7316 18.7504 41.35 16.1452 40.2113 13.2548Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt5")}</p>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path d="M11.7374 40.1032H11.25C9.48223 40.1032 8.59835 40.1032 8.04917 39.554C7.5 39.0049 7.5 38.121 7.5 36.3532V34.2683C7.5 33.2961 7.5 32.81 7.74971 32.3759C7.99942 31.9418 8.37614 31.7227 9.12957 31.2845C14.0898 28.3996 21.1342 26.7756 25.8357 29.5798C26.1516 29.7682 26.4357 29.9956 26.6785 30.2684C27.7249 31.4437 27.6487 33.2177 26.4427 34.2703C26.188 34.4926 25.9165 34.6612 25.6432 34.7197C25.8678 34.6937 26.0831 34.6639 26.2886 34.6311C27.9974 34.3585 29.4319 33.4453 30.7452 32.4533L34.1337 29.8936C35.3282 28.9912 37.1012 28.9911 38.296 29.8932C39.3716 30.7054 39.7005 32.0426 39.0205 33.1326C38.2275 34.4038 37.1102 36.03 36.0374 37.0236C34.963 38.0186 33.3635 38.907 32.0578 39.5372C30.6113 40.2354 29.0134 40.6377 27.388 40.9008C24.0916 41.4344 20.6561 41.3529 17.3931 40.6807C15.5485 40.3007 13.6327 40.1032 11.7374 40.1032Z" fill="#015EDF" />
                                            <path d="M20.3649 6.30628C21.3149 4.60209 21.7899 3.75 22.5 3.75C23.2101 3.75 23.6851 4.60209 24.6351 6.30628L24.8809 6.74717C25.1509 7.23145 25.2858 7.47359 25.4963 7.63336C25.7068 7.79312 25.9689 7.85243 26.4931 7.97104L26.9703 8.07902C28.8151 8.49642 29.7375 8.70511 29.9569 9.4108C30.1764 10.1165 29.5476 10.8518 28.2899 12.3224L27.9646 12.7029C27.6072 13.1208 27.4285 13.3298 27.3481 13.5883C27.2677 13.8468 27.2947 14.1256 27.3488 14.6832L27.398 15.1908C27.5881 17.1529 27.6832 18.134 27.1086 18.5702C26.5341 19.0063 25.6705 18.6087 23.9432 17.8134L23.4964 17.6076C23.0056 17.3816 22.7601 17.2686 22.5 17.2686C22.2399 17.2686 21.9944 17.3816 21.5036 17.6076L21.0568 17.8134C19.3295 18.6087 18.4659 19.0063 17.8914 18.5702C17.3168 18.134 17.4119 17.153 17.602 15.1908L17.6512 14.6832C17.7053 14.1256 17.7323 13.8468 17.6519 13.5883C17.5715 13.3298 17.3928 13.1208 17.0354 12.7029L16.7101 12.3224C15.4524 10.8518 14.8236 10.1165 15.0431 9.4108C15.2625 8.70511 16.1849 8.49642 18.0297 8.07902L18.5069 7.97104C19.0311 7.85243 19.2933 7.79312 19.5037 7.63336C19.7142 7.47359 19.8491 7.23145 20.1191 6.74718L20.3649 6.30628Z" fill="#015EDF" />
                                            <path d="M36.4324 14.4031C36.9074 13.551 37.1449 13.125 37.5 13.125C37.8551 13.125 38.0926 13.551 38.5676 14.4031L38.6904 14.6236C38.8254 14.8657 38.8929 14.9868 38.9981 15.0667C39.1034 15.1466 39.2344 15.1762 39.4965 15.2355L39.7352 15.2895C40.6576 15.4982 41.1187 15.6026 41.2285 15.9554C41.3382 16.3082 41.0238 16.6759 40.395 17.4112L40.2323 17.6015C40.0536 17.8104 39.9642 17.9149 39.9241 18.0441C39.8839 18.1734 39.8974 18.3128 39.9244 18.5916L39.949 18.8454C40.044 19.8265 40.0916 20.317 39.8043 20.5351C39.5171 20.7532 39.0852 20.5543 38.2216 20.1567L37.9982 20.0538C37.7528 19.9408 37.6301 19.8843 37.5 19.8843C37.3699 19.8843 37.2472 19.9408 37.0018 20.0538L36.7784 20.1567C35.9148 20.5543 35.4829 20.7532 35.1957 20.5351C34.9084 20.317 34.956 19.8265 35.051 18.8454L35.0756 18.5916C35.1026 18.3128 35.1161 18.1734 35.0759 18.0441C35.0358 17.9149 34.9464 17.8104 34.7677 17.6015L34.605 17.4112C33.9762 16.6759 33.6618 16.3082 33.7715 15.9554C33.8813 15.6026 34.3424 15.4982 35.2648 15.2895L35.5035 15.2355C35.7656 15.1762 35.8966 15.1466 36.0019 15.0667C36.1071 14.9868 36.1746 14.8657 36.3096 14.6236L36.4324 14.4031Z" fill="#015EDF" />
                                            <path d="M6.43244 14.4031C6.90743 13.551 7.14493 13.125 7.5 13.125C7.85507 13.125 8.09257 13.551 8.56756 14.4031L8.69045 14.6236C8.82543 14.8657 8.89292 14.9868 8.99815 15.0667C9.10338 15.1466 9.23443 15.1762 9.49654 15.2355L9.73517 15.2895C10.6576 15.4982 11.1187 15.6026 11.2285 15.9554C11.3382 16.3082 11.0238 16.6759 10.395 17.4112L10.2323 17.6015C10.0536 17.8104 9.96425 17.9149 9.92405 18.0441C9.88386 18.1734 9.89737 18.3128 9.92438 18.5916L9.94898 18.8454C10.044 19.8265 10.0916 20.317 9.80432 20.5351C9.51706 20.7532 9.08525 20.5543 8.22162 20.1567L7.99819 20.0538C7.75278 19.9408 7.63007 19.8843 7.5 19.8843C7.36993 19.8843 7.24722 19.9408 7.00181 20.0538L6.77838 20.1567C5.91475 20.5543 5.48294 20.7532 5.19568 20.5351C4.90842 20.317 4.95595 19.8265 5.05102 18.8454L5.07562 18.5916C5.10263 18.3128 5.11614 18.1734 5.07595 18.0441C5.03575 17.9149 4.94641 17.8104 4.76772 17.6015L4.60504 17.4112C3.97622 16.6759 3.66181 16.3082 3.77153 15.9554C3.88126 15.6026 4.34245 15.4982 5.26483 15.2895L5.50346 15.2355C5.76557 15.1762 5.89662 15.1466 6.00185 15.0667C6.10708 14.9868 6.17457 14.8657 6.30955 14.6236L6.43244 14.4031Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt6")}</p>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M3.80225 21.0938C4.52136 11.3952 12.618 3.75 22.5003 3.75C20.9424 3.75 19.5554 4.43289 18.4143 5.47711C17.279 6.5161 16.3195 7.96645 15.5435 9.68091C14.7629 11.4055 14.1566 13.4225 13.7462 15.6115C13.4172 17.3661 13.217 19.216 13.1503 21.0938H3.80225ZM3.80225 23.9062H13.1503C13.217 25.784 13.4172 27.6339 13.7462 29.3885C14.1566 31.5775 14.7629 33.5945 15.5435 35.3191C16.3195 37.0335 17.279 38.4839 18.4143 39.5229C19.5554 40.5671 20.9424 41.25 22.5003 41.25C12.618 41.25 4.52136 33.6048 3.80225 23.9062Z" fill="#015EDF" />
                                            <path d="M22.5003 6.36628C21.9847 6.36628 21.3185 6.58568 20.5439 7.29453C19.7636 8.00861 18.9824 9.12777 18.2923 10.6526C17.6067 12.1673 17.05 13.9958 16.6669 16.0391C16.3652 17.6483 16.178 19.3541 16.1123 21.0938H28.8883C28.8226 19.3541 28.6354 17.6483 28.3337 16.0391C27.9506 13.9958 27.3939 12.1673 26.7083 10.6526C26.0182 9.12778 25.237 8.00862 24.4567 7.29453C23.6821 6.58568 23.0159 6.36628 22.5003 6.36628Z" fill="#015EDF" />
                                            <path d="M16.6669 28.9609C17.05 31.0042 17.6067 32.8327 18.2923 34.3474C18.9824 35.8722 19.7636 36.9914 20.5439 37.7055C21.3185 38.4143 21.9847 38.6337 22.5003 38.6337C23.0159 38.6337 23.6821 38.4143 24.4567 37.7055C25.237 36.9914 26.0182 35.8722 26.7083 34.3474C27.3939 32.8327 27.9506 31.0042 28.3337 28.9609C28.6354 27.3517 28.8226 25.6459 28.8883 23.9062H16.1123C16.178 25.6459 16.3652 27.3517 16.6669 28.9609Z" fill="#015EDF" />
                                            <path d="M22.5003 3.75C24.0582 3.75 25.4452 4.43289 26.5863 5.47711C27.7217 6.5161 28.6811 7.96646 29.4571 9.68091C30.2377 11.4055 30.8441 13.4225 31.2544 15.6115C31.5834 17.3661 31.7836 19.216 31.8504 21.0938H41.1984C40.4793 11.3952 32.3826 3.75 22.5003 3.75Z" fill="#015EDF" />
                                            <path d="M31.2544 29.3885C30.8441 31.5775 30.2377 33.5945 29.4571 35.3191C28.6811 37.0335 27.7217 38.4839 26.5863 39.5229C25.4452 40.5671 24.0582 41.25 22.5003 41.25C32.3826 41.25 40.4793 33.6048 41.1984 23.9062H31.8504C31.7836 25.784 31.5834 27.6339 31.2544 29.3885Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt7")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-menu d-flex d-lg-none col-12 tasks ">
                            <div className="row">
                                <div className="col-10 col-sm-8 mb-5">
                                    <img className="w-100" src={img2} alt={404} />
                                </div>
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <circle cx="18.75" cy="12.6562" r="7.5" fill="#015EDF" />
                                            <ellipse cx="18.75" cy="33.2812" rx="13.125" ry="7.5" fill="#015EDF" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M34.4195 4.43295C34.819 3.76698 35.6828 3.55103 36.3488 3.95061L35.6253 5.15646C36.3488 3.95061 36.3482 3.95023 36.3488 3.95061L36.3514 3.95218L36.3544 3.95396L36.3613 3.95819L36.3793 3.96928C36.3931 3.97786 36.4104 3.98881 36.431 4.00216C36.4723 4.02884 36.5269 4.06517 36.5929 4.11135C36.7247 4.20364 36.9028 4.33583 37.1115 4.50975C37.528 4.85678 38.0726 5.37522 38.6149 6.08031C39.7078 7.50106 40.7816 9.67024 40.7816 12.6565C40.7816 15.6427 39.7078 17.8119 38.6149 19.2326C38.0726 19.9377 37.528 20.4561 37.1115 20.8032C36.9028 20.9771 36.7247 21.1093 36.5929 21.2016C36.5269 21.2478 36.4723 21.2841 36.431 21.3108C36.4104 21.3241 36.3931 21.3351 36.3793 21.3436L36.3613 21.3547L36.3544 21.359L36.3514 21.3607C36.3508 21.3611 36.3488 21.3623 35.6253 20.1565L36.3488 21.3623C35.6828 21.7619 34.819 21.5459 34.4195 20.88C34.0216 20.2168 34.234 19.3575 34.8934 18.9557L34.9038 18.9491C34.9175 18.9402 34.9435 18.9231 34.98 18.8975C35.0532 18.8462 35.1681 18.7616 35.311 18.6426C35.5977 18.4037 35.9906 18.0315 36.3857 17.5178C37.1678 16.5011 37.9691 14.9202 37.9691 12.6565C37.9691 10.3927 37.1678 8.81186 36.3857 7.79512C35.9906 7.28146 35.5977 6.90927 35.311 6.67037C35.1681 6.55131 35.0532 6.46671 34.98 6.41544C34.9435 6.38985 34.9175 6.37271 34.9038 6.36387L34.8934 6.35725C34.234 5.95547 34.0216 5.09613 34.4195 4.43295Z" fill="#015EDF" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M30.6175 8.27757C30.9648 7.58291 31.8095 7.30135 32.5042 7.64867L31.8753 8.90646C32.5042 7.64867 32.5035 7.6483 32.5042 7.64867L32.5072 7.6502L32.5105 7.65182L32.5175 7.65538L32.5336 7.66373L32.5741 7.6855C32.6046 7.70229 32.6421 7.72376 32.6857 7.75019C32.7726 7.80299 32.8843 7.87608 33.012 7.97189C33.2669 8.16305 33.5911 8.44856 33.9109 8.8483C34.5648 9.66561 35.1566 10.9086 35.1566 12.6565C35.1566 14.4043 34.5648 15.6473 33.9109 16.4646C33.5911 16.8644 33.2669 17.1499 33.012 17.341C32.8843 17.4368 32.7726 17.5099 32.6857 17.5627C32.6421 17.5892 32.6046 17.6106 32.5741 17.6274L32.5336 17.6492L32.5175 17.6575L32.5105 17.6611L32.5072 17.6627C32.5065 17.6631 32.5042 17.6642 31.8871 16.43L32.5042 17.6643C31.8095 18.0116 30.9648 17.73 30.6175 17.0354C30.2745 16.3493 30.5448 15.517 31.2207 15.1619C31.222 15.1611 31.2238 15.16 31.2261 15.1586C31.2416 15.1492 31.2765 15.1271 31.3245 15.091C31.4212 15.0185 31.5657 14.8939 31.7147 14.7077C31.9984 14.3531 32.3441 13.7211 32.3441 12.6565C32.3441 11.5918 31.9984 10.9598 31.7147 10.6053C31.5657 10.419 31.4212 10.2944 31.3245 10.2219C31.2765 10.1859 31.2416 10.1637 31.2261 10.1543C31.2238 10.1529 31.222 10.1519 31.2207 10.151C30.5448 9.79591 30.2745 8.96358 30.6175 8.27757Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt62")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-menu d-flex d-lg-none col-12 tasks mb-5">
                            <div className="row">
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.49587 6.49587C3.75 9.24175 3.75 13.6612 3.75 22.5C3.75 31.3388 3.75 35.7583 6.49587 38.5041C9.24175 41.25 13.6612 41.25 22.5 41.25C31.3388 41.25 35.7583 41.25 38.5041 38.5041C41.25 35.7583 41.25 31.3388 41.25 22.5C41.25 13.6612 41.25 9.24175 38.5041 6.49587C35.7583 3.75 31.3388 3.75 22.5 3.75C13.6612 3.75 9.24175 3.75 6.49587 6.49587ZM25.7812 18.75C25.7812 19.5266 26.4109 20.1562 27.1875 20.1562H28.48L24.7065 23.9298C24.5234 24.1129 24.2266 24.1129 24.0435 23.9298L21.0702 20.9565C19.7888 19.675 17.7112 19.675 16.4298 20.9565L12.1306 25.2556C11.5815 25.8048 11.5815 26.6952 12.1306 27.2444C12.6798 27.7935 13.5702 27.7935 14.1194 27.2444L18.4185 22.9452C18.6016 22.7621 18.8984 22.7621 19.0815 22.9452L22.0548 25.9185C23.3362 27.1999 25.4138 27.2 26.6952 25.9185L30.4688 22.145V23.4375C30.4688 24.2141 31.0984 24.8438 31.875 24.8438C32.6516 24.8438 33.2812 24.2141 33.2812 23.4375V18.75C33.2812 17.9733 32.6516 17.3438 31.875 17.3438H27.1875C26.4109 17.3438 25.7812 17.9733 25.7812 18.75Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt8")}</p>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.49587 6.49587C3.75 9.24175 3.75 13.6612 3.75 22.5C3.75 31.3388 3.75 35.7583 6.49587 38.5041C9.24175 41.25 13.6612 41.25 22.5 41.25C31.3388 41.25 35.7583 41.25 38.5041 38.5041C41.25 35.7583 41.25 31.3388 41.25 22.5C41.25 13.6612 41.25 9.24175 38.5041 6.49587C35.7583 3.75 31.3388 3.75 22.5 3.75C13.6612 3.75 9.24175 3.75 6.49587 6.49587ZM19.7683 14.0948C20.3039 13.5324 20.2822 12.6423 19.7198 12.1067C19.1574 11.5711 18.2673 11.5928 17.7317 12.1552L13.3929 16.7109L12.2683 15.5302C11.7327 14.9678 10.8426 14.9461 10.2802 15.4817C9.71777 16.0173 9.69606 16.9074 10.2317 17.4698L12.3745 19.7198C12.64 19.9985 13.008 20.1563 13.3929 20.1563C13.7777 20.1563 14.1458 19.9985 14.4112 19.7198L19.7683 14.0948ZM24.375 15.4688C23.5984 15.4688 22.9688 16.0984 22.9688 16.875C22.9688 17.6517 23.5984 18.2813 24.375 18.2813H33.75C34.5267 18.2813 35.1562 17.6517 35.1562 16.875C35.1562 16.0984 34.5267 15.4688 33.75 15.4688H24.375ZM19.7683 27.2198C20.3039 26.6574 20.2822 25.7673 19.7198 25.2317C19.1574 24.6961 18.2673 24.7178 17.7317 25.2802L13.3929 29.8359L12.2683 28.6552C11.7327 28.0928 10.8426 28.0711 10.2802 28.6067C9.71777 29.1423 9.69606 30.0324 10.2317 30.5948L12.3745 32.8448C12.64 33.1235 13.008 33.2813 13.3929 33.2813C13.7777 33.2813 14.1458 33.1235 14.4112 32.8448L19.7683 27.2198ZM24.375 28.5938C23.5984 28.5938 22.9688 29.2234 22.9688 30C22.9688 30.7767 23.5984 31.4063 24.375 31.4063H33.75C34.5267 31.4063 35.1562 30.7767 35.1562 30C35.1562 29.2234 34.5267 28.5938 33.75 28.5938H24.375Z" fill="#015EDF" />
                                        </svg>
                                        <p>{t("partt9")}</p>
                                    </div>
                                </div>
                                <div className="col-10 col-sm-8 task">
                                    <div className="card">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M33.4646 6.06494C33.75 6.75408 33.75 7.62772 33.75 9.375V35.625C33.75 37.3723 33.75 38.2459 33.4645 38.9351C33.4016 39.0871 33.329 39.234 33.2476 39.375C32.8372 40.086 32.2019 40.6469 31.4351 40.9646C30.7459 41.25 29.8723 41.25 28.125 41.25C26.3777 41.25 25.5041 41.25 24.8149 40.9646C24.0481 40.6469 23.4128 40.086 23.0024 39.375C22.921 39.234 22.8484 39.0871 22.7855 38.9351C22.5 38.2459 22.5 37.3723 22.5 35.625V9.375C22.5 7.62772 22.5 6.75408 22.7855 6.06494C22.8484 5.91288 22.921 5.76599 23.0024 5.625C23.4128 4.91403 24.0481 4.35307 24.8149 4.03545C25.5041 3.75 26.3777 3.75 28.125 3.75C29.8723 3.75 30.7459 3.75 31.4351 4.03545C32.2019 4.35307 32.8372 4.91403 33.2476 5.625C33.329 5.76599 33.4016 5.91288 33.4646 6.06494ZM29.5312 20.625C29.5312 19.8484 28.9016 19.2188 28.125 19.2188C27.3483 19.2188 26.7188 19.8484 26.7188 20.625V24.375C26.7188 25.1517 27.3483 25.7812 28.125 25.7812C28.9016 25.7812 29.5312 25.1516 29.5312 24.375V20.625Z" fill="#015EDF" />
                                            <path d="M19.9688 39.375C19.8241 38.8522 19.7621 38.3434 19.7297 37.8683C19.6874 37.2483 19.6875 36.5101 19.6875 35.7066V9.29342C19.6875 8.48989 19.6874 7.75169 19.7297 7.13172C19.7621 6.65664 19.8241 6.14781 19.9688 5.625H9.375C7.62772 5.625 6.75408 5.625 6.06494 5.91045C5.14608 6.29105 4.41605 7.02108 4.03545 7.93994C3.75 8.62908 3.75 9.50272 3.75 11.25C3.75 12.9973 3.75 13.8709 4.03545 14.5601C4.41605 15.4789 5.14608 16.2089 6.06494 16.5895C6.75408 16.875 7.62772 16.875 9.375 16.875C7.62772 16.875 6.75408 16.875 6.06494 17.1605C5.14608 17.5411 4.41605 18.2711 4.03545 19.1899C3.75 19.8791 3.75 20.7527 3.75 22.5C3.75 24.2473 3.75 25.1209 4.03545 25.8101C4.41605 26.7289 5.14608 27.4589 6.06494 27.8395C6.75408 28.125 7.62772 28.125 9.375 28.125C7.62772 28.125 6.75408 28.125 6.06494 28.4105C5.14608 28.7911 4.41605 29.5211 4.03545 30.4399C3.75 31.1291 3.75 32.0027 3.75 33.75C3.75 35.4973 3.75 36.3709 4.03545 37.0601C4.41605 37.9789 5.14608 38.7089 6.06494 39.0896C6.75408 39.375 7.62772 39.375 9.375 39.375H19.9688Z" fill="#015EDF" />
                                            <path d="M36.2814 39.3743C37.6029 39.3699 38.3382 39.3368 38.9351 39.0896C39.8539 38.7089 40.5839 37.9789 40.9646 37.0601C41.25 36.3709 41.25 35.4973 41.25 33.75C41.25 32.0027 41.25 31.1291 40.9646 30.4399C40.5839 29.5211 39.8539 28.7911 38.9351 28.4105C38.3819 28.1813 37.7099 28.1361 36.5625 28.1272V35.7066C36.5626 36.5101 36.5626 37.2483 36.5203 37.8683C36.4879 38.3432 36.426 38.8517 36.2814 39.3743Z" fill="#015EDF" />
                                            <path d="M36.5625 28.1228C37.7099 28.1139 38.3819 28.0687 38.9351 27.8395C39.8539 27.4589 40.5839 26.7289 40.9646 25.8101C41.25 25.1209 41.25 24.2473 41.25 22.5C41.25 20.7527 41.25 19.8791 40.9646 19.1899C40.5839 18.2711 39.8539 17.5411 38.9351 17.1605C38.3819 16.9313 37.7099 16.8861 36.5625 16.8772L36.5625 28.1228Z" fill="#015EDF" />
                                            <path d="M36.5625 16.8728C37.7099 16.8639 38.3819 16.8187 38.9351 16.5895C39.8539 16.2089 40.5839 15.4789 40.9646 14.5601C41.25 13.8709 41.25 12.9973 41.25 11.25C41.25 9.50272 41.25 8.62908 40.9646 7.93994C40.5839 7.02108 39.8539 6.29105 38.9351 5.91045C38.3382 5.66321 37.6029 5.63012 36.2814 5.62568C36.426 6.14826 36.4879 6.65685 36.5203 7.13172C36.5626 7.75169 36.5626 8.48987 36.5625 9.2934V16.8728Z" fill="#015EDF" />
                                        </svg>
                                        <p>Strong knowledge</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="avtorizatsiya my-5 pt-5">
                    <div className="write-us-img-mobile row d-flex d-md-none">
                        <div className="img-div">
                            <img src={img1} alt />
                        </div>
                    </div>
                    <div className="container log_con py-5 write-us-mobile">
                        <div className="row h-100">
                            <div className="col-12 col-md-5 d-flex align-items-center justify-content-center">
                                <img className=" d-none d-md-block" src={img1} alt="login" /></div>
                            <div className="col-12 col-md-7 position-relative">
                                <div className="chiz" />
                                <div className="row">
                                    <div className="col-12 col-md-8 offset-md-2">

                                        <h2 className="text-center top-text">{t("formpart1")}</h2>
                                        <div className="mt-3">
                                            <label className="label" htmlFor="phone">{t("formpart2")}</label>
                                            <div className="log_inp">
                                                <input onChange={handleInputChange} name="phone_number" id="phone" type="text" className="log_inp_in" placeholder={t('formpart2')} required />
                                                <span>
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z" stroke="#B1B9C5" strokeWidth="1.5" strokeLinecap="round">
                                                        </path>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label className="label" htmlFor="email">{t("formpart3")}</label>
                                            <div className="log_inp">
                                                <input onChange={handleInputChange} name="email" id="email" type="text" className="log_inp_in" placeholder="myemail@mail.com" required />
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                                        <g opacity="0.8">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M3.3335 4.16667C2.87707 4.16667 2.50016 4.54357 2.50016 5V15C2.50016 15.4564 2.87707 15.8333 3.3335 15.8333H16.6668C17.1233 15.8333 17.5002 15.4564 17.5002 15V5C17.5002 4.54357 17.1233 4.16667 16.6668 4.16667H3.3335ZM0.833496 5C0.833496 3.6231 1.95659 2.5 3.3335 2.5H16.6668C18.0437 2.5 19.1668 3.6231 19.1668 5V15C19.1668 16.3769 18.0437 17.5 16.6668 17.5H3.3335C1.95659 17.5 0.833496 16.3769 0.833496 15V5Z" fill="#64748B" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.984208 4.52154C1.24814 4.14449 1.76775 4.0528 2.14479 4.31673L10.0002 9.81554L17.8557 4.31673C18.2327 4.0528 18.7523 4.14449 19.0163 4.52154C19.2802 4.89858 19.1885 5.41818 18.8115 5.68211L10.4781 11.5154C10.1912 11.7163 9.80928 11.7163 9.52235 11.5154L1.18902 5.68211C0.811975 5.41818 0.720279 4.89858 0.984208 4.52154Z" fill="#64748B" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mt-3 who-r-u">
                                            <p className="label">{t("formpart4")}</p>
                                            <div className="choose-job">
                                                <input onChange={handleInputChange} required name="partnership_type" type="radio" value="university" id="university" />
                                                <label htmlFor="university">{t("formpart5")}</label>
                                                <input onChange={handleInputChange} required name="partnership_type" type="radio" value="agent" id="agent" />
                                                <label htmlFor="agent">{t("formpart6")}</label>
                                                <input onChange={handleInputChange} required name="partnership_type" type="radio" value="training-center" id="training-center" />
                                                <label htmlFor="training-center">{t("formpart62")}</label>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p className="label">{t("formpart7")}</p>
                                            <textarea placeholder="" onChange={handleInputChange} name="request_content" className="suggestions" defaultValue={""} ></textarea>

                                        </div>
                                        <button onClick={onSubmit} className="btn btn-primary w-100 py-3 mt-5">{t("p249")}</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="partnyor">
                <h1>{t("partt1")}</h1>
                <div className="partnyor_main ">
                    <div className="partnyor_left">
                        <h1>{t("partt2")}</h1>
                        <div className="partnyor_list"><img src={normal} alt="" />
                            <p>{t("partt3")}</p></div>
                        <div className="partnyor_list"><img src={normal} alt="" />
                            <p>{t("partt4")}</p></div>
                        <div className="partnyor_list"><img src={normal} alt="" />
                            <p>{t("partt5")}</p></div>
                        <div className="partnyor_list"><img src={normal} alt="" />
                            <p>{t("partt6")}</p></div>
                        <div className="partnyor_list"><img src={normal} alt="" />
                            <p>{t("partt7")}</p></div>
                        <div className="partnyor_list"><img src={normal} alt="" />
                            <p>{t("partt8")}</p></div>
                        <div className="partnyor_list"><img src={normal} alt="" />
                            <p>{t("partt9")}</p></div>
                    </div>
                    <div className="partnyor_right">
                        <h1>{t("formpart1")}</h1>
                        <div className="partnyor_input">
                            <p>{t("formpart2")}</p>
                            <input onChange={handleInputChange} required type="tel" name="phone_number" />
                        </div>
                        <div className="partnyor_input">
                            <p>{t("formpart3")}</p>
                            <input onChange={handleInputChange} required type="email" name="email" />
                        </div>
                        <div className="partnyor_radio">
                            <p>{t("formpart4")}</p>
                            <FormControl component="fieldset">
                                <RadioGroup onChange={handleInputChange} row aria-label="position" name="partnership_type" defaultValue="1">
                                    <FormControlLabel
                                        value="university"
                                        control={<Radio color="primary" />}
                                        label={t("formpart5")}
                                    />
                                    <FormControlLabel
                                        value="partner"
                                        control={<Radio color="primary" />}
                                        label={t("formpart6")}
                                    />
                                    <FormControlLabel
                                        value="center"
                                        control={<Radio color="primary" />}
                                        label={t("formpart62")}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="partnyor_input">
                            <p>{t("formpart7")}</p>
                            <textarea required onChange={handleInputChange} name="request_content" id="" cols="51" rows="6"></textarea>
                        </div>
                        <a onClick={onSubmit} href="#">{t("formpart8")}</a>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    );
}

export default Partnyors;